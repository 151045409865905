<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
			@close="refresh()"
		>
			<form ref="tableForm">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">
							{{ formTitle }}
						</p>
					</header>
					<section class="modal-card-body">
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Jenis Kartu Identitas*">
									<b-select  
										v-model="active_identity.identity_type_id" 
										@input="updateIdentityName"
										placeholder="Jenis Kartu Identitas" 
										expanded
										:disabled="isEditable"
									>
										<option 
											v-for="identity in identity_type"
											:key="identity.id"
											:value="identity.id"
											:disabled="identity.id == 4"
										>{{ identity.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column" v-show="this.active_identity.identity_type_id <= 4">
								<b-field
									label="Nomor Kartu*"
								>
									<b-input
										:disabled="!this.active_identity.identity_type_id"
										@keypress.native="onlyNumber"
										placeholder="Nomor Kartu"
										validation-message="Silahkan isi sesuai jenis identitas anda"
										v-model="active_identity.identity_number"
										:maxlength="identityCounter"
										:minlength="identityCounter"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column" v-show="this.active_identity.identity_type_id >= 7">
								<b-field
									label="Nomor Kartu*"
								>
									<b-input
										:disabled="!this.active_identity.identity_type_id"
										@keypress.native="onlyNumber"
										placeholder="Nomor Kartu"
										validation-message="Silahkan isi sesuai jenis identitas anda"
										v-model="active_identity.identity_number"
										:maxlength="identityCounter"
										:minlength="identityCounter - 2"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column" v-show="this.active_identity.identity_type_id === 6">
								<b-field
									label="Nomor Kartu*"
								>
									<b-input
										:disabled="!this.active_identity.identity_type_id"
										@keypress.native="pasporValidation"
										placeholder="Nomor Kartu"
										validation-message="Silahkan isi sesuai jenis identitas anda"
										v-model="active_identity.identity_number"
										:maxlength="identityCounter"
										:minlength="identityCounter - 2"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column" v-show="this.active_identity.identity_type_id === 5">
								<b-field
									label="Nomor Kartu*"
								>
									<b-input
										:disabled="!this.active_identity.identity_type_id"
										@keypress.native="onlyNumber"
										placeholder="Nomor Kartu"
										validation-message="Silahkan isi sesuai jenis identitas anda"
										v-model="active_identity.identity_number"
										:maxlength="identityCounter + 1"
										:minlength="identityCounter"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tanggal Mulai Berlaku">
									<b-datepicker
										placeholder="Klik untuk memilih tanggal"
										v-model="active_identity.temp_valid_date"
										@input="saveValidDate"
										:min-date="minDate"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Tanggal Berakhir">
									<b-datepicker
										placeholder="Klik untuk memilih tanggal"
										v-model="active_identity.temp_invalid_date"
										@input="saveInvalidDate"
										:min-date="minDateInvalid"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
							</div>
						</div>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>
							Kembali
						</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="confirmEdit()"
							:loading="isLoading"
						>
							Simpan & Lanjut
						</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
					2 Kartu Identitas
				</h4>
			</div>
		</div>
		<div class="columns">
			<div class="column">
				<b-notification					
					type="is-danger is-light"
					aria-close-label="Close notification"
					role="alert"
					:closable="false">
					<p class="is-size-7-mobile">Ketentuan pengisian form:</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Nomor KTP Wajib Diisi
					</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Kartu Keluarga Wajib Diisi
					</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						NPWP Wajib Diisi (Jika belum ada bisa dilewati dulu)
					</p>
				</b-notification>
			</div>
		</div>

		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right" 
						@click="add()" 
						:loading="isLoading"
						expanded
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-table 
			class="box mt-6"
			:data="identities" 
			:loading="isLoading"
		>
			<b-table-column
				field="identity_type.name"
				label="Jenis Kartu Identitas"
				v-slot="props" 
			>
				{{ props.row.identity_number + " - " + props.row.identity_type.name }}
			</b-table-column>
			<b-table-column
				field="action"
				label="Aksi"
				v-slot="props"
				centered 
			>
				<b-button  
					class="button is-warning is-light" 
					@click="editIdentity(props.row)"
				>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next()"
				expanded
				:loading="isLoading"
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapGetters, mapActions } from "vuex"
	import { dateFormat } from '@/plugins/timeformat.js'
	
	export default {
		created() {
			this.refresh();
		},
		data() {
			const today = new Date()
			return {
				isCardModalActive: false,
				isLoading: false,
				isEditable: false,
				identity_type: [],
				newIdentities: {
					ktp: {
						identity_type_id: 4,
						number: null,
						readonly: true,
						invalid_date: null
					},
					kk: {
						identity_type_id: 3,
						number: null,
						readonly: true,
						invalid_date: null
					},
					sim_A: {
						identity_type_id: 7,
						number: null,
						invalid_date: null
					},
					sim_B1: {
						identity_type_id: 8,
						number: null,
						invalid_date: null
					},
					sim_B2: {
						identity_type_id: 9,
						number: null,
						invalid_date: null
					},
					sim_C: {
						identity_type_id: 10,
						number: null,
						invalid_date: null
					}
				},
				identities: [],
				active_identity: {
					id: null,
					applicant_id: null,
					identity_number: null,
					identity_type_id: null,
					identity_type: {
						code: null,
						id: null,
						name: null
					},
					valid_date: null,
					invalid_date: null
				},
				active_identity_default: {
					id: null,
					applicant_id: null,
					identity_number: null,
					identity_type_id: null,
					identity_type: {
						code: null,
						id: null,
						name: null
					},
					valid_date: null,
					invalid_date: null
				},
				edited_index: -1,
				minDate: new Date(today.getFullYear() - 70, today.getMonth(), today.getDate()),
				minDateInvalid: today
			}
		},
		mounted() {
			this.refresh()
		},
		computed: {
			...mapGetters ({
				applicant: "applicant",
				stepper_position: "stepper_position"
			}),
			formTitle() {
				return this.edited_index === -1 
					? 'Tambah Data Kartu Identitas' 
					: 'Ubah Data Kartu Identitas'
			},
			identityRules() {
				if (this.active_identity.identity_type_id === 3 || this.active_identity.identity_type_id === 4){
					return this.rules.ktp;
				}else if (this.active_identity.identity_type_id === 5) { 
					return this.rules.npwp; 
				} else return this.rules.not_empty
			},
			identityCounter() {
				if (this.active_identity.identity_type_id === 5){
					return 15
				} else if (this.active_identity.identity_type_id === 3 || this.active_identity.identity_type_id === 4)
					return 16
				else if (this.active_identity.identity_type_id === 2)
					return 11
				else if (this.active_identity.identity_type_id === 1)
					return 13
				else if (this.active_identity.identity_type_id > 6)
					return 16
				else if (this.active_identity.identity_type_id === 6)
					return 8
				else
					return ''
			}
		},
		methods: {
			...mapActions({
				setApplicant: "setApplicant",
				setStepperPosition: "setStepperPosition"
			}),
			async next() {
				const foundKTP = this.identities.some(item => item.identity_type_id == 4)
				const foundKK = this.identities.some(item => item.identity_type_id == 3)
				if (!foundKTP) { 
					this.$buefy.snackbar.open({
						message: 'Data KTP belum terisi, silahkan mengisi terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
				else if (!foundKK) { 
					this.$buefy.snackbar.open({
						message: 'Data KK belum terisi, silahkan mengisi terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
				else { 
					this.setStepperPosition(2)
				}
			},
			confirmEdit() {
				// check ktp bpjs
				if (!(/^[0-9]+$/i.test(this.active_identity.identity_number)) && (this.active_identity.identity_type_id <= 5 || this.active_identity.identity_type_id >= 7)) {
					this.$buefy.snackbar.open({
							message: "Gagal! Format kartu hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (!(/^[A-Za-z0-9]+$/i.test(this.active_identity.identity_number)) && this.active_identity.identity_type_id == 6) {
					this.$buefy.snackbar.open({
							message: "Gagal! Format kartu hanya bisa angka dan alfabet",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (/^(\d)\1*$/.test(this.active_identity.identity_number)) { //check same digit
					this.$buefy.snackbar.open({
							message: "Gagal! Nomor kartu yang diinput tidak valid",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (this.active_identity.valid_date && this.active_identity.invalid_date) {
					if (this.active_identity.valid_date >= this.active_identity.invalid_date) {
						this.$buefy.snackbar.open({
							message: "Gagal! Tanggal mulai berlaku harus lebih kecil dari tanggal berakhir",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
				}

				if (this.edited_index > -1 && this.active_identity.identity_type_id == 4) {
					this.$buefy.dialog.confirm({
						message: "Apakah anda yakin ingin menyimpan " + this.active_identity.identity_number + " sebagai nomor " + this.active_identity.identity_type.name + " anda?",
						onConfirm: () => this.saveIdentity()
					})
				} else {
					this.saveIdentity()
				}
            },
			add() {
				this.edited_index = -1
				this.isCardModalActive = true
				this.isLoading = false
				this.isEditable = false
				this.active_identity = Object.assign({}, this.active_identity_default)
				this.minDateInvalid = new Date()
			},
			async closeModal() {
				this.isCardModalActive = false
				this.isLoading = false
				this.isEditable = false
				this.active_identity = Object.assign({}, this.active_identity_default)
				this.edited_index = -1
				await this.refresh()
			},
			refresh() {
				this.identities = []
				this.isLoading = true;
				this.$axios
					.get("applicants/" + this.applicant.id + "/identities")
					.then(response => {
						this.identities = response.data.data;

						for (let i = 0; i < this.identities.length; i++) {
							if (this.identities[i].identity_type_id == 4) {
								this.newIdentities.ktp.number = this.identities[i].identity_number
							} else if (this.identities[i].identity_type_id == 3) {
								this.newIdentities.kk.number = this.identities[i].identity_number
							} else if (this.identities[i].identity_type_id == 7) {
								this.newIdentities.sim_A.number = this.identities[i].identity_number
							} else if (this.identities[i].identity_type_id == 8) {
								this.newIdentities.sim_B1.number = this.identities[i].identity_number
							} else if (this.identities[i].identity_type_id == 9) {
								this.newIdentities.sim_B2.number = this.identities[i].identity_number
							} else if (this.identities[i].identity_type_id == 10) {
								this.newIdentities.sim_C.number = this.identities[i].identity_number
							}
						}
					})
					.catch(e => {
						this.message = "Error: " + e.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					})
					.finally(() => {
						this.isLoading = false;
					});

				if (!this.identity_type.length) {
					this.$axios
						.get("identity-types")
						.then(response => {							
							this.identity_type = response.data.data;
						})
						.catch(e => {
							this.message = "Error: " + e.response.data.message;
							this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						});
				}
			},
			saveIdentity() {
				if (this.$refs.tableForm.checkValidity()) {
					this.isLoading = true;
					this.active_identity.applicant_id = this.applicant.id;
					this.message = null;
					let temp = Object.assign({}, this.active_identity);

					if (this.edited_index > -1) {
						this.$axios
							.patch(`applicants/` + this.applicant.id + "/identities/" + this.active_identity.id, this.active_identity)
							.then(response => {
								this.message = response.data.message;

								if (response.data.status) {
									Object.assign(this.identities[this.edited_index], temp);
									this.closeModal();
								}
							})
							.catch(e => {
								this.message = "Error: " + e.response.data.message;
								return false;
							})
							.finally(() => {
								this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
								})
								this.isLoading = false;
								this.active_identity = Object.assign({}, this.active_identity_default)
								this.minDateInvalid = new Date()
							});
					} else {
						this.$axios
							.post(`applicants/` + this.applicant.id + `/identities`,this.active_identity)
							.then(response => {
								this.message = response.data.message;

								this.closeModal();
							})
							.catch(e => {
								this.message = "Error: " + e.response.data.message;
								return false;
							})
							.finally(() => {
								this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
								})
								this.isLoading = false;
								this.active_identity = Object.assign({}, this.active_identity_default)
								this.minDateInvalid = new Date()
							});
					}
				} else {
					this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					// this.active_identity = Object.assign({}, this.active_identity_default)
					return false;
				}
			},
			saveIdentityNew() {
				if (this.$refs.form.checkValidity()) {
					this.isLoading = true;
					this.message = null;

					this.$axios
						.post(
							`applicants/` + this.applicant.id + `/identities/new`,
							this.newIdentities
						)
						.then(response => {
							this.message = response.data.message;

							if (response.data.status) {
								// this.identities.push(this.active_identity)
							}
						})
						.catch(e => {
							this.message = "Error: " + e.response.data.message;
							return false;
						})
						.finally(() => {
							this.closeModal();
							this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						});
				} else {
					this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					this.isLoading = false;
					return false;
				}
			},
			updateIdentityName() {
				this.active_identity.identity_number = null
				for (var i = 0; i < this.identity_type.length; i++) {
					if (
						this.identity_type[i].id === this.active_identity.identity_type_id
					) {
						this.active_identity.identity_type.name = this.identity_type[i].name;
						break;
					}
				}
			},
			editIdentity(identity) {
				if (identity.identity_type_id == 4) {
					this.isEditable = true;
				} else {
					this.isEditable = false;
				}
				this.isEditable = true;
				this.edited_index = this.identities.indexOf(identity)
				this.active_identity = Object.assign({}, identity)
				this.active_identity.temp_valid_date = this.active_identity.valid_date == null 
					? null 
					: new Date(this.active_identity.valid_date)
				this.active_identity.temp_invalid_date = this.active_identity.invalid_date == null
					? null
					: new Date(this.active_identity.invalid_date)
				this.isCardModalActive = true;

				if (this.active_identity.temp_valid_date) {
					this.minDateInvalid = new Date(this.active_identity.temp_valid_date.getFullYear(), this.active_identity.temp_valid_date.getMonth(), this.active_identity.temp_valid_date.getDate() + 1)
				} else {
					this.minDateInvalid = new Date()
				}
			},
			saveValidDate(date) {
				this.active_identity.valid_date = dateFormat(date);
				this.minDateInvalid = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
			},
			saveInvalidDate(date) {
				this.active_identity.invalid_date = dateFormat(date);
			},
			prev() {
				this.setStepperPosition(0)
			},
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					$event.preventDefault();
				}
			},
			pasporValidation($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9]+$/i.test(char)) { //check if alphabet numeric
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			// npwpValidation($event) {
			// 	let char = String.fromCharCode($event.keyCode);
			// 	if (/^[0-9.-]+$/i.test(char)) { //check if numeric dot hyphen / dash
			// 		return true;
			// 	} else {
			// 		$event.preventDefault();
			// 		return false;
			// 	}
			// },
			// simValidation($event) {
			// 	let char = String.fromCharCode($event.keyCode);
			// 	if (/^[0-9-]+$/i.test(char)) { //check if numeric dot hyphen / dash
			// 		return true;
			// 	} else {
			// 		$event.preventDefault();
			// 		return false;
			// 	}
			// }
		},
	}
</script>